import React from "react"
import Layout from '../components/layout'
import { Container, Header, Grid} from 'semantic-ui-react';
//import about from '../styles/about.module.scss';

const AboutPage = () => {
    return(
        <Layout page="internal">
             <Container>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                        <Header as='h1'>About Us</Header>
                            <p>Elite Pro Creative (EPC), previously known as Elite Pro, was established in 2006 as a visual design and web development company. After over 14 years of providing creative solutions to clients, our company has realigned to provide marketing and digital strategies that drives the effort of our clients. Our innovative approach to problem-solving makes our clients become more competitive within their market. Our aim is to archive the business goals of our clients while keeping their customer engaged and satisfied. At Elite Pro Creative, we provide a strategic approach to a distinct identity.</p> 

                            <p>Our interdisciplinary teams consist analytic and intuitive thinkers that pushes the boundary of creativity. Members embrace a human-centered approach which allows them to empathize with the target audience of our clients. Our enthusiastic professionals range from graphic designers, product designers, web developers, project managers, digital marketers, and business consultants.</p>  
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row >
                        <Grid.Column>
                        <Header as='h2'>Clients Served</Header>
                            <p>Elite Pro Creative (EPC), previously known as Elite Pro, was established in 2006 as a visual design and web development company. After over 14 years of providing creative solutions to clients, our company has realigned to provide marketing and digital strategies that drives the effort of our clients. Our innovative approach to problem-solving makes our clients become more competitive within their market. Our aim is to archive the business goals of our clients while keeping their customer engaged and satisfied. At Elite Pro Creative, we provide a strategic approach to a distinct identity.</p> 

                            <p>Our interdisciplinary teams consist analytic and intuitive thinkers that pushes the boundary of creativity. Members embrace a human-centered approach which allows them to empathize with the target audience of our clients. Our enthusiastic professionals range from graphic designers, product designers, web developers, project managers, digital marketers, and business consultants.</p>  
                        </Grid.Column>
                    </Grid.Row>
                </Grid>   
            
             </Container>
        </Layout>
    ) 
}

export default AboutPage;

